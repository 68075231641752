import { Heading, List, ListItem, Text } from "@chakra-ui/react";
import React from "react";
import PageLayout from "../components/layout/PageLayout";

const Privacy = () => {
  return (
    <PageLayout>
      <Heading as="h2">Política de Privacidad</Heading>
      <br/>
      <List>
        <ListItem>
          Las datos capturados por este sitio solo pueden ser accedidos por usted y las personas autorizadas.
        </ListItem>
        <ListItem>
          Sus datos no serán cedidos a terceras partes fuera de Residencial La Hacienda.
        </ListItem>
        <ListItem>
          Sus datos solo serán utilizados para la gestión de la relación con usted y para la gestión de los servicios que ofrecemos.
        </ListItem>
        <br/>
        <Text>Vigente desde Mayo, 2021</Text>
      </List>
    </PageLayout>
  )
}
export default Privacy;